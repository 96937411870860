@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ki45iv');
  src:  url('fonts/icomoon.eot?ki45iv#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ki45iv') format('truetype'),
    url('fonts/icomoon.woff?ki45iv') format('woff'),
    url('fonts/icomoon.svg?ki45iv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Warning:before {
  content: "\e900";
}
.icon-Success:before {
  content: "\e903";
}
.icon-Error:before {
  content: "\e904";
}
.icon-Info:before {
  content: "\e906";
}
.icon-Send:before {
  content: "\e957";
}
.icon-Edit:before {
  content: "\e916";
}
.icon-Available:before {
  content: "\e948";
}
.icon-Disabled:before {
  content: "\e949";
}
.icon-Change-Password:before {
  content: "\e941";
}
.icon-Eye:before {
  content: "\e938";
}
.icon-Closed-Eye:before {
  content: "\e937";
}
.icon-Notification:before {
  content: "\e90d";
}
.icon-Close:before {
  content: "\e928";
}
.icon-Upload:before {
  content: "\e91c";
}
.icon-Attachment:before {
  content: "\e91d";
}
.icon-Checked:before {
  content: "\e921";
}
.icon-Arrow-Bottom:before {
  content: "\e922";
}
.icon-Arrow-Top:before {
  content: "\e92b";
}
.icon-Arrow-Left:before {
  content: "\e923";
}
.icon-Arrow-Right:before {
  content: "\e924";
}
