@mixin title() {
  margin: 0 0 16px 0;
  color: var(--color-text);
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
}

@mixin sub-title() {
  color: var(--color-text);
  font-weight: 500;

  @media (min-width: 769px) {
    margin: 0 0 20px 0;
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
    font-size: 15px;
    line-height: 17px;
  }
}

@mixin back-title() {
  margin: 0 0 16px 0;
  display: flex;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;
    &:hover {
      .icon {
        border-color: var(--color-link);
      }
    }
    .icon {
      width: 25px;
      height: 25px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      border-radius: 5px;
      border: 1px solid #161618;
    }
  }
}

@mixin drop-down() {
  position: absolute;
  width: 140px;
  top: 45px;
  right: 0;
  background: #1f1f22;
  border-radius: 5px;

  ul {
    margin: 0;
    list-style: none;
    padding: 0;

    li {
      padding: 11px 12px;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 1px solid #27272c;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: var(--color-link);
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

@mixin close-icon() {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--color-text);
  font-size: 8px;
  border-radius: 5px;
  border: 1px solid #2a2c30;
  transition: all 0.25s;
  cursor: pointer;

  &:hover {
    color: var(--color-link);
  }
}

@mixin icon-button {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 4px;
  color: white;
  border: 1px solid #2a2e35;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;

  width: 24px;
  height: 24px;
  font-size: 12px;
}
